import dayjs from "dayjs";
import { getCurrencySymbol } from "halifax";
import {
  Airline,
  Airport,
  BookedFlightItineraryWithDepartureTime,
  FiatPrice,
  FlightItinerarySlice,
  HomesReservation,
  HotelCfarContract,
  IBucketedDate,
  IDateBucket,
  IMonthBucket,
  MultiTravelItinerary,
  PaymentBreakdown,
  PaymentLineItemEnum,
  PaymentLineItemRewards,
  PaymentLineItemTravelWallet,
  PaymentLineItemTravelWalletEnum,
  PaymentLineItemUserCard,
  Reservation,
  SingleTravelItinerary,
  TravelItinerary,
  TravelItineraryEnum,
  getSlicesFromTravelItinerary,
  hasPassedTheTimeWindowToRebookTheDepartureFlight,
} from "redmond";

export const formatFiatValue = (value: number) =>
  value.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
export const formatRewardsValue = (value: number) =>
  value.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
export const getCurrencyString = ({
  currencyCode,
  currencySymbol,
  value,
}: {
  currencyCode: string;
  currencySymbol: string;
  value: number;
}) => `${currencyCode} ${currencySymbol}${formatFiatValue(value)}`;

export const getFlightPNR = (booking: BookedFlightItineraryWithDepartureTime) =>
  booking?.bookedItinerary.travelItinerary.locators?.agent.value ?? "";

export const getFirstFlightCarrier = (
  booking: BookedFlightItineraryWithDepartureTime
) => {
  const itinerary = booking.bookedItinerary.travelItinerary;
  const { TravelItinerary } = itinerary;
  let firstItinerary: SingleTravelItinerary | undefined;
  let firstCarrier = "";

  if (TravelItinerary === TravelItineraryEnum.MultiTravelItinerary) {
    [firstItinerary] = (itinerary as MultiTravelItinerary).travelItineraries;
  } else if (TravelItinerary === TravelItineraryEnum.SingleTravelItinerary) {
    firstItinerary = itinerary as SingleTravelItinerary;
  }

  if (firstItinerary) {
    firstCarrier = firstItinerary.slices[0].segments[0]?.marketingAirline.code;
  }

  return firstCarrier;
};

/**
 * @param {IDateBucket[]} dateBuckets
 * @return {IMonthBucket[]}
 */
export const transformDateBuckets = (
  dateBuckets: IDateBucket[]
): IMonthBucket[] => {
  const processedMonths = dateBuckets.reduce(
    (months, { dates }, bucketIndex) => {
      const reducedMonths = dates.reduce(
        (monthBuckets: IMonthBucket[], dateString: string) => {
          const date = dayjs(dateString).toDate();
          const bucketedDate: IBucketedDate = { bucket: bucketIndex, date };
          const currentMonthIndex = dayjs(date).month();
          let existingMonthBucket = monthBuckets.find(({ monthIndex }) => {
            return monthIndex === currentMonthIndex;
          });

          if (typeof existingMonthBucket === "undefined") {
            existingMonthBucket = { monthIndex: currentMonthIndex, dates: [] };
            monthBuckets.push(existingMonthBucket);
          }

          existingMonthBucket.dates.push(bucketedDate);

          return monthBuckets;
        },
        months
      );

      return reducedMonths;
    },
    [] as IMonthBucket[]
  );
  const sortedMonths = processedMonths.map((processedMonth) => {
    const sortedDates = processedMonth.dates.sort(
      (a: IBucketedDate, b: IBucketedDate) => dayjs(a.date).diff(b.date)
    );
    return { monthIndex: processedMonth.monthIndex, dates: sortedDates };
  });

  return sortedMonths;
};

export const getStopText = (numStops: number) =>
  numStops === 0 ? "Nonstop" : `${numStops} ${numStops > 1 ? "stops" : "stop"}`;

export const getSliceBasicInfo = (
  slice: FlightItinerarySlice,
  airportMap: { [key: string]: Airport },
  airlineMap: { [key: string]: Airline },
  timeIntervalFormatter?: string
) => {
  const lastSegment = slice.segments[slice.segments.length - 1];
  const firstSegment = slice.segments[0];
  const location = airportMap[lastSegment.destination.locationCode];
  const destination = location
    ? `${location.cityName} (${location?.code})`
    : "";
  const formatter = timeIntervalFormatter ?? "H:mm A";
  const date = dayjs(firstSegment.updatedDeparture).format("ddd, MMM D");
  const timeInterval = `${dayjs(firstSegment.updatedDeparture).format(
    formatter
  )} - ${dayjs(lastSegment.updatedArrival).format(formatter)}`;

  const durationRaw = dayjs.duration(
    dayjs(lastSegment.zonedUpdatedArrival ?? lastSegment.updatedArrival).diff(
      dayjs(firstSegment.zonedUpdatedDeparture ?? firstSegment.updatedDeparture)
    )
  );

  const duration =
    durationRaw.asMinutes() >= dayjs.duration({ days: 1 }).asMinutes()
      ? durationRaw.format("D[d] H[h] m[m]")
      : durationRaw.asMinutes() >= dayjs.duration({ hours: 1 }).asMinutes()
      ? durationRaw.format("H[h] m[m]")
      : durationRaw.format("m[m]");

  const stopCount =
    slice.segments.reduce(
      (currentSum, segment) => currentSum + segment.stops + 1,
      0
    ) - 1;
  const fareShelfBrandName = slice.fareShelf?.brandName ?? "";
  const marketingAirlines = slice.segments.map((segment) => {
    return {
      airlineCode: segment.marketingAirline.code,
      airlineName: airlineMap[segment.marketingAirline.code]?.displayName ?? "",
    };
  });

  return {
    destination,
    date,
    timeInterval,
    duration,
    stopCount,
    fareShelfBrandName,
    marketingAirlines,
  };
};

export const getIsSelectingReturnFromTravelItinerary = (
  travelItinerary: TravelItinerary
) => {
  const slices = getSlicesFromTravelItinerary(travelItinerary);
  const departureSlice = slices?.[0];
  const returnSlice = slices?.[1];
  const lastDepartureSegment =
    departureSlice?.segments[departureSlice.segments.length - 1];

  return (
    !!returnSlice &&
    !!lastDepartureSegment &&
    hasPassedTheTimeWindowToRebookTheDepartureFlight(lastDepartureSegment)
  );
};

export const calculateHotelTotals = ({
  reservation,
  paymentBreakdown,
  cfar,
}: {
  reservation: Reservation;
  paymentBreakdown: PaymentBreakdown;
  cfar?: HotelCfarContract;
}): {
  paidTotal: FiatPrice;
  calculatedTripTotal: FiatPrice;
  refundableAmount: string;
} => {
  const { premium } = cfar ?? {};
  const paidCashTotal: PaymentLineItemUserCard | undefined =
    paymentBreakdown.payments.find(
      (p) => p.PaymentLineItem == PaymentLineItemEnum.UserCard
    ) as PaymentLineItemUserCard;
  const travelWalletItems = paymentBreakdown.payments.filter(
    (p) => p.PaymentLineItem == PaymentLineItemEnum.TravelWallet
  ) as PaymentLineItemTravelWallet[];

  const paidTravelWalletTotal = travelWalletItems.reduce((total, item) => {
    return total + item.amount.amount;
  }, 0);

  const paidTravelWalletOffer = travelWalletItems.find(
    (t) => t.TravelWallet == PaymentLineItemTravelWalletEnum.TravelWalletOffer
  );

  const paidRewardsTotal: PaymentLineItemRewards | undefined =
    paymentBreakdown.payments.find(
      (p) => p.PaymentLineItem == PaymentLineItemEnum.Rewards
    ) as PaymentLineItemRewards;
  const paidTotal: FiatPrice = {
    currencyCode: paidCashTotal?.amount.currency ?? "USD",
    currencySymbol: getCurrencySymbol(paidCashTotal?.amount.currency ?? "USD"),
    value:
      (paidCashTotal?.amount.amount ?? 0) +
      (paidRewardsTotal?.amount.fiatValue.amount ?? 0) +
      paidTravelWalletTotal,
  };
  const paidTotalAfterApplyingOffer: FiatPrice = {
    ...paidTotal,
    value: paidTotal.value - (paidTravelWalletOffer?.amount.amount ?? 0),
  };

  const extraFeeValue = reservation.pricing.feeBreakdown.total?.value ?? 0;
  const calculatedTripTotalValue = paidTotal
    ? paidTotal.value + extraFeeValue
    : reservation.pricing.tripTotal.fiat.value;

  // this refundable amount is derivative of 'paidTotalAfterApplyingOffer' because travel offers are not refundable
  const refundableAmount = cfar?.shouldRefundCfarPremium
    ? paidTotalAfterApplyingOffer
    : {
        ...paidTotalAfterApplyingOffer,
        value:
          paidTotalAfterApplyingOffer.value - (premium?.amount.fiat.value ?? 0),
      };

  return {
    paidTotal,
    calculatedTripTotal: {
      currencyCode: paidCashTotal?.amount.currency ?? "USD",
      currencySymbol: paidTotal.currencySymbol,
      value: calculatedTripTotalValue,
    },
    refundableAmount: refundableAmount.value.toFixed(2),
  };
};

export const getPaxCount = ({
  bookedItinerary,
}: BookedFlightItineraryWithDepartureTime) => {
  return [
    ...bookedItinerary.passengers.alone,
    ...bookedItinerary.passengers.withLapInfants.map((p) => p.adult),
  ].filter((p) => (p.type as any) === "ADT").length;
};
export const calculateHomeTotals = (
  reservation: HomesReservation,
  paymentBreakdown: PaymentBreakdown
): { paidTotal: FiatPrice; calculatedTripTotal: FiatPrice } => {
  const paidCashTotal: PaymentLineItemUserCard | undefined =
    paymentBreakdown.payments.find(
      (p) => p.PaymentLineItem == PaymentLineItemEnum.UserCard
    ) as PaymentLineItemUserCard;
  const paidTravelWalletTotal: PaymentLineItemTravelWallet | undefined =
    paymentBreakdown.payments.find(
      (p) => p.PaymentLineItem == PaymentLineItemEnum.TravelWallet
    ) as PaymentLineItemTravelWallet;
  const paidRewardsTotal: PaymentLineItemRewards | undefined =
    paymentBreakdown.payments.find(
      (p) => p.PaymentLineItem == PaymentLineItemEnum.Rewards
    ) as PaymentLineItemRewards;
  const paidTotal: FiatPrice = {
    currencyCode: paidCashTotal?.amount.currency ?? "USD",
    currencySymbol: getCurrencySymbol(paidCashTotal?.amount.currency ?? "USD"),
    value:
      (paidCashTotal?.amount.amount ?? 0) +
      (paidRewardsTotal?.amount.fiatValue.amount ?? 0) +
      (paidTravelWalletTotal?.amount.amount ?? 0),
  };

  const calculatedTripTotalValue = paidTotal
    ? paidTotal.value
    : reservation.rate.detailedPrice.payNowTotal.fiat.value;

  return {
    paidTotal,
    calculatedTripTotal: {
      currencyCode: paidCashTotal?.amount.currency ?? "USD",
      currencySymbol: paidTotal.currencySymbol,
      value: calculatedTripTotalValue,
    },
  };
};
