// TODO: Move v0 to separate folder
export const apiVersionPrefix: string = "/api/v0";
export const apiV1Prefix: string = "/api/v1";
export const apiVersionPrefixV2: string = "/api/v2";

// Itinerary
export const flightItinerariesApiPrefix: string = `${apiVersionPrefix}/itinerary/flight`;
export const hotelItinerariesApiPrefix: string = `${apiVersionPrefix}/itinerary/hotel`;
export const groundItinerariesApiPrefix: string = `${apiVersionPrefix}/itinerary/ground`;
export const homesItinerariesApiPrefix: string = `${apiVersionPrefix}/itinerary/homes`;
export const pendingRequestItinerariesApiPrefix: string = `${apiVersionPrefix}/pendingRequests/list`;
export const packageItinerariesApiPrefix: string = `${apiVersionPrefix}/itinerary/package`;

// Flight Disruptions
export const flightDisruptionExerciseApiPrefix: string = `${apiV1Prefix}/flight/disruptions/exercise`;
export const getFlightDisruptionExerciseEligibilityPath: string = `${flightDisruptionExerciseApiPrefix}/eligibility`;
export const prepareFlightRefundPath: string = `${flightDisruptionExerciseApiPrefix}/prepareFlightRefund`;
export const submitFlightRefundPath: string = `${flightDisruptionExerciseApiPrefix}/submitFlightRefund`;

const ancillariesApiPrefix: string = `${apiVersionPrefixV2}/ancillaries`;
const ancillariesPostBookingApiPrefix: string = `${ancillariesApiPrefix}/postbooking`;
export const ancillaryPostBookingMyTripsOfferPath = `${ancillariesPostBookingApiPrefix}/offer/myTrips/get`;

// Others
export const rewardsApiPrefix: string = `${apiVersionPrefix}/rewards`;
export const watchApiPrefix: string = `${apiVersionPrefix}/alert/watch`;
export const flightPriceFreezePrefix: string = `${apiVersionPrefix}/pricefreeze`;
export const hotelPriceFreezePrefix: string = `${apiVersionPrefix}/hotels/pricefreeze`;
export const flightPriceFreezeList: string = `${flightPriceFreezePrefix}/list`;
export const fetchUserInfoPath: string = `${apiVersionPrefix}/userInfo`;
export const resendEmailPrefix: string = `${apiVersionPrefix}/selfserve/resend`;
export const analyticsApiPrefix: string = `${apiVersionPrefix}/tracking`;
export const analyticsEventApi: string = `${analyticsApiPrefix}/event`;
export const experimentsApiPrefix: string = `${apiVersionPrefix}/experiments`;
export const locationAutocompleteApiPrefix: string = `${apiVersionPrefix}/autocomplete/location`;
export const fetchCalendarPath: string = `${apiVersionPrefix}/calendar`;

// Self serve
const selfServePrefix = `${apiVersionPrefix}/selfserve`;
const selfServeHotelsPrefix = `${selfServePrefix}/hotels`;
const selfServeGroundPrefix = `${selfServePrefix}/ground`;
const selfServeHomesPrefix = `${selfServePrefix}/homes`;

export const confirmFlightCancellationPath = `${selfServePrefix}/cancelV2`;
export const confirmFlightCfarCancellationPath = `${confirmFlightCancellationPath}/cfar`;
export const getFlightCancellationInfoPathV3 = `${selfServePrefix}/farerules/cancelV3`;
export const getFlightCfarCancellationInfoPathV3 = `${getFlightCancellationInfoPathV3}/cfar`;
export const getFlightExchangePolicyPath = `${selfServePrefix}/farerules/exchange`;
export const getFlightExchangeEligibilityQuotePath = `${selfServePrefix}/flight/exchangeEligibilityQuote`;
export const cancelHotelByReservationPath = `${selfServeHotelsPrefix}/cancel`;
export const getHotelCancellationInfoPath = `${cancelHotelByReservationPath}/policy`;
export const getHotelChangeInfoPath = `${selfServeHotelsPrefix}/change/policy`;
export const getCfarHotelCancellationPath = `${getHotelCancellationInfoPath}/cfar`;
export const cancelCfarHotelPath = `${cancelHotelByReservationPath}/cfar`;

export const getGroundChangeInfoPath = `${selfServeGroundPrefix}/change/policy`;
export const cancelGroundByReservationPath = `${selfServeGroundPrefix}/cancel`;
export const getGroundCancellationInfoPath = `${cancelGroundByReservationPath}/policy`;

export const requestTravelerEditsPath = `${selfServePrefix}/modifyTravelerInfo`;

export const fetchUserPassengersPath = `${apiVersionPrefix}/passengers/list`;
export const deleteUserPassengerPath = `${apiVersionPrefix}/passengers/delete`;
export const updateUserPassengerPath = `${apiVersionPrefix}/passengers/update`;

export const getHomesCancelQuotePath = `${selfServeHomesPrefix}/cancelQuote`;
export const agentCancelHomeFulfillPath = `${selfServeHomesPrefix}/cancelAgentFulfill`;
// Cross sell
export const lodgingLocationAutocompleteApiPrefix: string = `${apiVersionPrefix}/autocomplete/lodging`;
export const hotelAvailabilityApiPrefix: string = `${apiVersionPrefix}/hotels/availability`;
export const crossSellActiveOffers = `${apiVersionPrefix}/crosssell/offers/active`;

// Hotel Price Freeze
export const hotelPriceFreezeListSummariesApi: string = `${hotelPriceFreezePrefix}/listSummaries`;
export const fetchHotelPriceFreezeDetail = `${hotelPriceFreezePrefix}/getDetail`;
export const refundHotelPriceFreezeApiPath = `${hotelPriceFreezePrefix}/refund`;

// Travel wallet
export const userApiPrefix = `${apiVersionPrefix}/user`;
export const travelWalletApiPrefix = `${userApiPrefix}/wallet`;

// Payment methods
export const paymentMethodsApiPrefix: string = `${apiVersionPrefix}/paymentMethods`;
