import React, { useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { MobilePackageHotelDetailsConnectorProps } from "./container";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Popover, Typography } from "@material-ui/core";
import { getHotelStayDuration } from "../../../../utils/constants";
import * as hotelTextConstants from "../HotelCard/constants";
import { SummaryCard } from "../SummaryCard";
import { HotelMap } from "../HotelCard/components/HotelMap";
import {
  HotelCancellation,
  HotelCardRoomTypeContent,
  HotelCheckInInstructions,
} from "../HotelCard/components/HotelInfoParts";
import {
  addPackageType,
  getPostalCode,
} from "../PackageCard/DesktopPackageHotelCard/component";
import {
  getTwoLineAddresses,
  IActionLink,
  Icon,
  IconName,
  StatusTag,
} from "halifax";
import {
  HotelItineraryState,
  LodgingAddressExact,
  TagColors,
  MyTripsFilter,
  MyTripsModalTypes,
  HotelConfirmationCodeEnum,
} from "redmond";
import clsx from "clsx";
import {
  calculateHotelTotals,
  getCurrencyString,
} from "../../../../../../utils/helpers";
import { HOW_REFUNDS_WORK_LINK, HOW_REFUNDS_WORK_TEXT } from "../../constants";
import { MobileActionLinks } from "../MobileActionLinks";
import { PaymentSummary } from "../PaymentSummary";
import * as textConstants from "../PackageCard/constants";
import { ConfirmationSummary } from "../ConfirmationSummary";
export interface IMobilePackageHotelDetailsProps
  extends RouteComponentProps,
    MobilePackageHotelDetailsConnectorProps {}

export const MobilePackageHotelDetails = ({
  packageHotel,
  setSelectedPackageHotel,
  tripsFilter,
  history,
  packageItinerary,
  setOpenModal,
}: IMobilePackageHotelDetailsProps) => {
  const refundPopoverRef = useRef<HTMLButtonElement>(null);
  const [refundPopoverOpen, setRefundPopoverOpen] = useState(false);

  if (!packageHotel || !packageItinerary) {
    return null;
  }
  const { paymentBreakdown, pricingBreakdown } = packageItinerary;

  const hotel = packageHotel;
  const { reservation } = packageHotel;
  const resState = reservation?.state?.ReservationState;

  const totals = paymentBreakdown
    ? calculateHotelTotals({
        reservation,
        paymentBreakdown: packageItinerary.paymentBreakdown!,
      })
    : undefined;

  const isCancelled = [
    HotelItineraryState.Canceled,
    HotelItineraryState.CancellationFailure,
    HotelItineraryState.Cancelled,
  ].includes(resState);
  const isPastTrips = tripsFilter === MyTripsFilter.PAST_TRIPS;

  const { title, confirmationCode, startDate, endDate, subtitle } =
    hotelTextConstants.getHotelInfoDetails(packageHotel, true);
  const nights = getHotelStayDuration(packageHotel.reservation);
  const onOpenModal = (type: MyTripsModalTypes) =>
    setOpenModal({
      type,
      selectedItinerary: addPackageType(packageItinerary),
    });
  const hotelAddress = reservation.lodgingData?.address as
    | LodgingAddressExact
    | undefined;

  // parse address to only show the street, this could lead to potential bugs
  // because BE returns everything as a full address i.e: "15 Beacon St, Boston, MA, US"
  const hotelStreet = getTwoLineAddresses(hotelAddress).line1;

  const actions: IActionLink[] = [];
  const topActions: IActionLink[] = []; // render at the top of the page

  const closeRefundPopover = () => setRefundPopoverOpen(false);
  const openRefundPopover = () => setRefundPopoverOpen(true);

  if (!isCancelled && !isPastTrips) {
    actions.push({
      content: "Cancel Hotel Reservation",
      onClick: () => onOpenModal(MyTripsModalTypes.CancelHotel),
    });
  } else if (isCancelled) {
    topActions.push({
      content: (
        <>
          {HOW_REFUNDS_WORK_LINK}
          <Icon className="info-icon" name={IconName.InfoCircle} />
        </>
      ),
      linkClassName: "how-refunds-work-link",
      linkRef: refundPopoverRef,
      onClick: openRefundPopover,
    });
  }

  actions.push({
    content: "Resend Confirmation",
    onClick: () => onOpenModal(MyTripsModalTypes.ResendConfirmation),
  });

  return (
    <Box className="mobile-trip-details">
      <Box className="mobile-trip-details-header">
        <FontAwesomeIcon
          icon={faChevronLeft}
          onClick={() => {
            history.goBack();
            setSelectedPackageHotel(null);
          }}
        />
        <Box className="header-info">
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="caption">{nights}</Typography>
        </Box>
      </Box>
      <Box className={"mobile-trip-card mobile-trip-summary-card"}>
        {isCancelled && (
          <StatusTag
            className="title-status-tag"
            tagInfo={{
              label: hotelTextConstants.CANCELED,
              type: TagColors.RED,
            }}
          />
        )}
        <Typography variant="body1" className="trips-title">
          {title}
        </Typography>
        {subtitle && <Typography variant="caption">{subtitle}</Typography>}
        <Box className="info-container">
          <Typography className="label" variant="caption">
            {textConstants.CONFIRMATION}
          </Typography>
          <Typography
            className={clsx("code", { warning: isCancelled })}
            variant="caption"
          >
            {confirmationCode}
          </Typography>
        </Box>
      </Box>
      <SummaryCard className="mobile-trip-card mobile-location-details">
        <Box className="mobile-location-details-container">
          <Box className="mobile-hotel-dates">
            <Typography variant="subtitle2" className="date-label">
              {`${textConstants.CHECK_IN}: `}
            </Typography>
            <Typography variant="subtitle2">{startDate}</Typography>
            <Typography variant="subtitle2">|</Typography>
            <Typography variant="subtitle2" className="date-label">
              {`${textConstants.CHECK_OUT}: `}
            </Typography>
            <Typography variant="subtitle2">{endDate}</Typography>
          </Box>
          <Box className="mobile-hotel-address">
            <Typography variant="body1" className="hotel-address-line">
              {hotelStreet}
            </Typography>
            <Typography variant="caption" className="address-details">
              {`${getPostalCode(reservation)}`}
            </Typography>
            {reservation.lodgingData?.phone && (
              <Typography variant="caption" className="hotel-phone">
                {reservation.lodgingData.phone}
              </Typography>
            )}
          </Box>
          <HotelMap lodging={reservation.lodgingData} hideLabel />
        </Box>
      </SummaryCard>
      <SummaryCard className="mobile-trip-card mobile-trip-room-type">
        <HotelCardRoomTypeContent bookedRooms={hotel.reservation.bookedRooms} />
      </SummaryCard>
      {totals?.refundableAmount && (
        <SummaryCard className="mobile-trip-card cancellation-summary">
          <HotelCancellation
            cancellationPolicy={reservation.cancellationPolicy}
            refundAmount={totals?.refundableAmount}
          />
        </SummaryCard>
      )}
      {reservation.checkInDate && reservation.checkOutDate && (
        <SummaryCard className="mobile-trip-card check-in-instructions">
          <HotelCheckInInstructions
            fromDate={hotel.reservation.checkInDate}
            untilDate={hotel.reservation.checkOutDate}
            checkInInstructions={
              hotel.reservation.lodgingData.checkInInstructions
            }
          />
        </SummaryCard>
      )}
      {paymentBreakdown && (
        <SummaryCard
          className="mobile-payment-summary mobile-trip-card"
          action={
            <FontAwesomeIcon
              className="mobile-right-chevron"
              onClick={() => onOpenModal(MyTripsModalTypes.PaymentModal)}
              icon={faChevronRight}
            />
          }
        >
          <PaymentSummary
            tripTotalAmount={getCurrencyString(
              pricingBreakdown.payNowAmount.fiat
            )}
            cardLabel={
              textConstants.getLabelsFromPaymentBreakdown(paymentBreakdown)
                .cardLabel
            }
            cardAmount={textConstants.getValues(paymentBreakdown).cardValue}
            rewardsLabel={
              textConstants.getLabelsFromPaymentBreakdown(paymentBreakdown)
                .rewardLabel
            }
            rewardsAmount={
              textConstants.getValues(paymentBreakdown).rewardValue
            }
            showCardLabel={Boolean(
              textConstants.getLabelsFromPaymentBreakdown(paymentBreakdown)
                .cardLabel
            )}
            showRewardsLabel={Boolean(
              textConstants.getLabelsFromPaymentBreakdown(paymentBreakdown)
                .rewardLabel
            )}
          />
        </SummaryCard>
      )}
      <SummaryCard
        className="mobile-trip-card confirmation-summary"
        action={
          hotel.reservation.hotelConfirmationCode &&
          hotel.reservation.hotelConfirmationCode.HotelConfirmationCode ==
            HotelConfirmationCodeEnum.HotelConfirmationConfirmed ? (
            <FontAwesomeIcon
              className="mobile-right-chevron"
              onClick={() => onOpenModal(MyTripsModalTypes.ConfirmationModal)}
              icon={faChevronRight}
            />
          ) : null
        }
      >
        <ConfirmationSummary confirmationCode={confirmationCode} />
      </SummaryCard>

      <MobileActionLinks actions={actions} />
      {isCancelled && (
        <Popover
          anchorEl={refundPopoverRef.current}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          className="how-refunds-work-popover"
          onClose={closeRefundPopover}
          open={refundPopoverOpen}
        >
          <Typography className="subtitle">{HOW_REFUNDS_WORK_TEXT}</Typography>
        </Popover>
      )}
    </Box>
  );
};
