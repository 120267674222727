import React, {
  MouseEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  faChevronLeft,
  faChevronRight,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Popover, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { ActionLinks, IActionLink, Icon, IconName, StatusTag } from "halifax";
import { RouteComponentProps } from "react-router-dom";
import {
  getFlightInfoDetails,
  MyTripsModalTypes,
  getCurrencyPrefix,
  getPriceWithDecimal,
  ScheduleChangeSeverity,
  getReturnSlice,
  PortalItineraryStatusEnum,
  MyTripsFilter,
  AirlineMap,
  ItineraryEnum,
  ExerciseEligibilityV1,
  ExerciseEligibilityV1Enum,
  isMultiCityItinerary,
  getMulticityFlightInfoDetails,
  SingleTravelItinerary,
} from "redmond";

import { AddOnsSummary, AddOn, AddOnEnum } from "../AddOnsSummary";
import { ConfirmationSummary } from "../ConfirmationSummary";
import { getFlightCardBanner, getTitleTag } from "../FlightCard/component";
import * as textConstants from "../FlightCard/constants";
import {
  addFlightType,
  getDPPostBookking,
  getIsDpExercisedViaRebook,
  getIsDpExercisedViaRefund,
  getIsDpAlreadyExercised,
  getLabels,
  getValues,
  hasCfarExpired,
  isFlightCanceled,
  flightMeetsMcpConditions,
} from "../FlightCard/helpers";
import { getConfirmationNumbers } from "../ItinerariesModal/components/ConfirmationModalContent/component";
import { ItinerarySummary } from "../ItinerarySummary";
import { MobileActionLinks } from "../MobileActionLinks";
import { PaymentSummary } from "../PaymentSummary";
import { RedeemFTCButton } from "../RedeemFTCButton";
import { SummaryCard } from "../SummaryCard";
import { TravelersSummary } from "../TravelersSummary";
import { MobileFlightItineraryDetailsConnectorProps } from "./container";
import {
  ActiveExperiments,
  useExperiment,
} from "../../../../../../context/experiments";
import {
  getOriginalItineraryId,
  isViMultiTicket,
  isWithin24Hours,
} from "../../../../utils";
import { pushToDisruptionOverview } from "../../../../../../utils/queryStringHelpers";
import { HOW_REFUNDS_WORK_LINK, HOW_REFUNDS_WORK_TEXT } from "../../constants";

import "./styles.scss";
import {
  DisruptionProtectionRebookEnum,
  getDepartureSlice,
} from "redmond/trips-module/itinerary";
import { ClientContext } from "../../../../../../App";
import { PostBookingOfferBanner } from "../FlightCard/components/PostBookingOfferBanner";

interface IMobileFlightItineraryDetailsProps
  extends RouteComponentProps,
    MobileFlightItineraryDetailsConnectorProps {
  showDisruptionProtectionElements?: boolean;
}

export const MobileFlightItineraryDetails = ({
  flight,
  airportMap,
  airlineMap,
  setSelectedFlight,
  history,
  setOpenModal,
  setSelectedFlightDisruptionProtectionItineraryId,
  tripsFilter,
  showDisruptionProtectionElements,
  flightDisruptions,
  upcomingItineraries,
  fetchFlightDisruptionsCallState,
  hasUpcomingReturnFlightInBookedItineraryAfterRebook,
  isDisruptionProtection24hRuleEnabled,
  mcpViItineraries,
  paymentMethods,
}: IMobileFlightItineraryDetailsProps) => {
  const cfarExp = useExperiment(ActiveExperiments.CFAR);
  const flightExchangeExp = useExperiment(ActiveExperiments.FlightExchange);
  const ftcPopoverRef = useRef<HTMLButtonElement>(null);
  const refundsPopoverRef = useRef<HTMLButtonElement>(null);
  const [ftcPopoverOpen, setFTCPopoverOpen] = useState(false);
  const [refundsPopoverOpen, setRefundsPopoverOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!flight) {
    return null;
  }

  const flightNonNull = flight;

  const {
    status,
    travelCredit,
    travelCreditCopy = "",
    bookedItinerary,
    ancillaries,
    departureTime,
  } = flight;
  const { cfar, delay, missedConnection } = ancillaries;
  const { id: itineraryId } = bookedItinerary;

  const clientContext = useContext(ClientContext);
  const { isAgentPortal } = clientContext;

  const isCanceled = status === PortalItineraryStatusEnum.Canceled;
  const isPastTrips = tripsFilter === MyTripsFilter.PAST_TRIPS;
  const hasCfar = cfarExp && !!cfar;

  const dpExerciseEligibility: ExerciseEligibilityV1 | undefined =
    flightDisruptions?.eligibilityByItinerary[bookedItinerary.id];

  const hasDp = !!delay || !!missedConnection;

  const isDpExercisedViaRebook = getIsDpExercisedViaRebook(
    delay,
    missedConnection
  );

  const isDpExercisedViaRefund = getIsDpExercisedViaRefund(
    delay,
    missedConnection
  );

  const isDpAlreadyExercised = getIsDpAlreadyExercised(
    delay,
    missedConnection
  );

  const isDpExercised = isDpExercisedViaRebook || isDpExercisedViaRefund || isDpAlreadyExercised;
  const isViItinerary = isViMultiTicket(flight.bookedItinerary.multiTicketType);
  const hasAndShowDp = hasDp && !!showDisruptionProtectionElements;
  const sharedDpEligible = hasAndShowDp && !isDpExercised;
  const notCanceledDpEligible = sharedDpEligible && !isCanceled;
  const isCanceledDpEligible =
    sharedDpEligible &&
    isCanceled &&
    itineraryId !== undefined &&
    flightDisruptions?.eligibilityByItinerary[itineraryId]
      ?.ExerciseEligibilityV1 === ExerciseEligibilityV1Enum.IsEligible;

  // note: hasAddOn can be expanded in the future as we have more features added in
  const hasAddOn = hasCfar || (hasAndShowDp && !isDpExercised);

  const hasFTC = !!travelCredit && !!travelCreditCopy;
  const topActions: IActionLink[] = []; // renders at the top of the page

  const isMulticity = isMultiCityItinerary(bookedItinerary, airportMap);
  const isRebookedFromMissedConnectionVi = getOriginalItineraryId(
    flight?.disruptionProtectionRebook
  );

  const closeFTCPopover = () => setFTCPopoverOpen(false);

  const closeRefundsPopover = () => setRefundsPopoverOpen(false);

  const goToExchange = (e?: MouseEvent) => {
    e?.stopPropagation();
    onOpenModal(MyTripsModalTypes.ExchangeFlight);
  };

  const goToDisruptionProtection = (e?: MouseEvent) => {
    const flights = upcomingItineraries.flights;
    const eligibilities = flightDisruptions.eligibilityByItinerary;

    e?.stopPropagation();
    setSelectedFlightDisruptionProtectionItineraryId(itineraryId);
    pushToDisruptionOverview({
      history,
      itineraryId,
      tracking:
        flights && eligibilities
          ? {
              flights,
              eligibilities,
            }
          : undefined,
      path: isViItinerary ? "vi-rebook-connection" : "main",
    });
  };

  const openFTCPopover = () => setFTCPopoverOpen(true);

  const openRefundsPopover = () => setRefundsPopoverOpen(true);

  const onOpenModal = (type: MyTripsModalTypes) => {
    setOpenModal({
      type,
      selectedItinerary: addFlightType(flight),
    });
  };

  const getActions = () => {
    const actions: IActionLink[] = [];

    if (!isCanceled && !isPastTrips) {
      if (!isViItinerary && !isRebookedFromMissedConnectionVi) {
        actions.push({
          content: textConstants.CHANGE_FLIGHT,
          onClick: goToExchange,
        });
      }
    }

    if (notCanceledDpEligible || isCanceledDpEligible) {
      actions.push({
        linkClassName: "mobile-disruption-protection-link",
        content: (
          <>
            <Icon
              className="disruption-protection-icon"
              name={IconName.DisruptionProtectionBlue}
            />
            {textConstants.GET_HELP_DISRUPTED_FLIGHT}
          </>
        ),
        onClick: goToDisruptionProtection,
      });
    }

    // VI rebook by mcp button
    // currently we have no control to know which departarure time to check, so we loop itineraries to find something that match 24hrs
    if (
      isViItinerary &&
      flightMeetsMcpConditions(
        flight,
        isDisruptionProtection24hRuleEnabled,
        isAgentPortal
      )
    ) {
      actions.push({
        content: textConstants.REBOOK_CONNECTING_FLIGHT,
        onClick: goToDisruptionProtection,
      });
    }
    /*
        note: replace cancel-flight with cancel-for-any-reason when CFAR is included;
        when CFAR has expired, /cancelV3 will return a ContactCustomerService | AirlineControl cancellation scenario,
        and that can be consumed / addressed by the SelfServeCancelFlight modal.
      */
    if (!isCanceled && !isPastTrips) {
      if (hasCfar && !hasCfarExpired(cfar)) {
        actions.push({
          linkClassName: "mobile-cfar-link",
          content: (
            <>
              <Icon className="cfar-icon" name={IconName.CheckShieldBlue} />
              {textConstants.CANCEL_FOR_ANY_REASON}
            </>
          ),
          onClick: () => onOpenModal(MyTripsModalTypes.CfarFlight),
          disabled: isFlightCanceled(flight),
        });
      } else {
        if (!isRebookedFromMissedConnectionVi) {
          actions.push({
            content: textConstants.CANCEL_FLIGHT,
            onClick: () => onOpenModal(MyTripsModalTypes.SelfServeCancelFlight),
          });
        }
      }
    }

    if (!isPastTrips && isWithin24Hours(departureTime)) {
      // add Check-In action to top of list
      actions.unshift({
        content: (
          <>
            Check-in for your flight
            <FontAwesomeIcon className="check-in-icon" icon={faEdit} />
          </>
        ),
        onClick: () => onOpenModal(MyTripsModalTypes.CheckInFlight),
      });
    }

    // always show this option regardless of cancellation status
    actions.push({
      content: textConstants.RESEND_CONFIRMATION,
      onClick: () => onOpenModal(MyTripsModalTypes.ResendConfirmation),
    });

    return actions;
  };

  if (isCanceled) {
    if (hasFTC) {
      topActions.push({
        content: (
          <>
            {textConstants.HOW_TC_WORKS}
            <Icon className="info-icon" name={IconName.InfoCircle} />
          </>
        ),
        linkClassName: "how-ftc-works-link",
        linkRef: ftcPopoverRef,
        onClick: openFTCPopover,
      });
    } else {
      topActions.push({
        content: (
          <>
            {HOW_REFUNDS_WORK_LINK}
            <Icon className="info-icon" name={IconName.InfoCircle} />
          </>
        ),
        linkClassName: "how-refunds-work-link",
        linkRef: refundsPopoverRef,
        onClick: openRefundsPopover,
      });
    }
  }

  const formatDate = (date: string) =>
    dayjs(date).format(textConstants.DATE_FORMAT);

  const hasMajorScheduleChange =
    flight.bookedItinerary.scheduleChange?.severity ===
    ScheduleChangeSeverity.Major;
  const hasMinorScheduleChange =
    flight.bookedItinerary.scheduleChange?.severity ===
      ScheduleChangeSeverity.Minor ||
    (flight.status === PortalItineraryStatusEnum.Modified &&
      !flight.bookedItinerary.scheduleChange);
  const { confirmationCode, subtitle, title } = isMulticity
    ? getMulticityFlightInfoDetails(
        flight.bookedItinerary,
        formatDate,
        airportMap,
        airlineMap
      )
    : getFlightInfoDetails(
        flight.bookedItinerary.travelItinerary.locators,
        getDepartureSlice(flight.bookedItinerary),
        getReturnSlice(flight.bookedItinerary),
        formatDate,
        airportMap,
        airlineMap
      );

  const mobileBanner = getFlightCardBanner({
    isMobile: true,
    isMobileDetailsPage: true,
    flight,
    hasMajorScheduleChange,
    hasMinorScheduleChange,
    airlineMap,
    setOpenModal: () =>
      setOpenModal({
        type: isViItinerary
          ? MyTripsModalTypes.VirtualInterlineMcp
          : MyTripsModalTypes.ScheduleChange,
        selectedItinerary: { ...flight, type: ItineraryEnum.Flight },
      }),
    hasAndShowDp,
    isDpExercisedViaRebook,
    isDpExercisedViaRefund,
    isDpAlreadyExercised,
    hasUpcomingReturnFlight:
      hasUpcomingReturnFlightInBookedItineraryAfterRebook(
        flight.bookedItinerary
      ),
    dpExerciseEligibility,
    goToDisruptionProtection,
    fetchFlightDisruptionsCallState,
    mcpViItineraries,
  });

  const postBooking = getDPPostBookking(flight);

  const dpAddOn = (addOnItems: AddOn[], forPostBooking: boolean) => {
    if (hasAndShowDp && !isDpExercised && forPostBooking == !!postBooking) {
      addOnItems.push({
        onClick: goToDisruptionProtection,
        isEnabled: !isFlightCanceled(flight),
        type: AddOnEnum.DisruptionProtection,
      });
    }
  };

  const addOnItems: AddOn[] = [];
  dpAddOn(addOnItems, false);
  if (hasCfar) {
    addOnItems.push({
      expiryDate: cfar?.expired ?? "",
      expiredTz: "America/New_York", // TODO: Fall back to EST / EDT for now
      onClick: () => onOpenModal(MyTripsModalTypes.CfarFlight),
      isEnabled: !hasCfarExpired(cfar) && !isFlightCanceled(flight),
      type: AddOnEnum.Cfar,
    });
  }

  const postBookingAddOnItems: AddOn[] = [];
  dpAddOn(postBookingAddOnItems, true);

  const addOnsSummary = (type: "post-booking" | "booking", addOns: AddOn[]) => {
    function label() {
      switch (type) {
        case "post-booking":
          const dp = getDPPostBookking(flightNonNull);
          if (dp?.created) {
            return textConstants.POST_BOOKING_ADD_ON_DATE(dp.created);
          }
          return undefined;
        case "booking":
          return undefined;
      }
    }

    return (
      <SummaryCard className="mobile-add-ons-summary mobile-trip-card">
        <AddOnsSummary
          addOns={(() => {
            return addOns;
          })()}
          label={label()}
        />
      </SummaryCard>
    );
  };

  const getSummaryCards = () => {
    if (isMulticity) {
      return (
        <>
          {(
            flight.bookedItinerary.travelItinerary as SingleTravelItinerary
          ).slices.map((_tripSlice, index) => {
            return (
              <SummaryCard
                key={`mobile-trip-card mobile-outgoing-itinerary-summary-${index}`}
                className="mobile-trip-card mobile-outgoing-itinerary-summary"
                action={
                  <FontAwesomeIcon
                    className="mobile-right-chevron"
                    onClick={() =>
                      onOpenModal(
                        MyTripsModalTypes[`MulticityItinerary${index}`]
                      )
                    }
                    icon={faChevronRight}
                  />
                }
              >
                <ItinerarySummary
                  flight={flight}
                  isOutgoing
                  airlineMap={airlineMap}
                  airportMap={airportMap}
                  multicitySliceIndex={index}
                />
              </SummaryCard>
            );
          })}
        </>
      );
    } else {
      return (
        <>
          <SummaryCard
            className="mobile-trip-card mobile-outgoing-itinerary-summary"
            action={
              <FontAwesomeIcon
                className="mobile-right-chevron"
                onClick={() => onOpenModal(MyTripsModalTypes.OutboundItinerary)}
                icon={faChevronRight}
              />
            }
          >
            <ItinerarySummary
              flight={flight}
              isOutgoing
              airlineMap={airlineMap}
              airportMap={airportMap}
            />
          </SummaryCard>
          {!!getReturnSlice(flight.bookedItinerary) && (
            <SummaryCard
              className="mobile-trip-card"
              action={
                <FontAwesomeIcon
                  className="mobile-right-chevron"
                  onClick={() => onOpenModal(MyTripsModalTypes.ReturnItinerary)}
                  icon={faChevronRight}
                />
              }
            >
              <ItinerarySummary
                flight={flight}
                isOutgoing={false}
                airlineMap={airlineMap}
                airportMap={airportMap}
              />
            </SummaryCard>
          )}
        </>
      );
    }
  };

  return (
    <Box className="mobile-trip-details">
      <Box className="mobile-trip-details-header">
        <FontAwesomeIcon
          icon={faChevronLeft}
          onClick={() => {
            history.goBack();
            setSelectedFlight(null);
          }}
        />
        <Box className="header-info">
          <Typography variant="subtitle1">
            {isMulticity
              ? textConstants.getMulticityFlightHeader(flight, airportMap)
              : textConstants.getFlightHeader(flight)}
          </Typography>
          <Typography variant="caption">
            {isMulticity
              ? textConstants.MULTICITY_FLIGHT
              : getReturnSlice(flight.bookedItinerary)
              ? textConstants.ROUND_TRIP
              : textConstants.ONE_WAY}
          </Typography>
        </Box>
      </Box>
      {mobileBanner}
      {topActions.length ? (
        <>
          <ActionLinks actions={topActions} />
          {flightExchangeExp && isCanceled && hasFTC && (
            <RedeemFTCButton
              isMobile
              onClick={goToExchange}
              travelCredit={travelCredit}
            />
          )}
        </>
      ) : null}
      <Box
        className={clsx(
          "mobile-trip-card",
          "mobile-trip-summary-card",
          "parent",
          "stretch"
        )}
      >
        <Box className="mobile-trip-card mobile-trip-summary-card child">
          {!!getTitleTag(flight, airlineMap as AirlineMap) && (
            <StatusTag
              className="title-status-tag"
              tagInfo={
                getTitleTag(
                  flight,
                  airlineMap as AirlineMap,
                  hasMajorScheduleChange
                )!
              }
            />
          )}
          <Typography variant="body1" className="trips-title">
            {title}
          </Typography>
          {subtitle && <Typography variant="caption">{subtitle}</Typography>}
          {confirmationCode && (
            <Box className="info-container">
              <Typography className="label" variant="caption">
                {textConstants.CONFIRMATION}
              </Typography>
              <Typography
                className={clsx("code", {
                  warning: isCanceled,
                })}
                variant="caption"
              >
                {confirmationCode}
              </Typography>
            </Box>
          )}
        </Box>
        <PostBookingOfferBanner itineraryId={itineraryId} />
      </Box>
      <Box
        className={clsx("mobile-summary-card-section", {
          "has-add-on": hasAddOn,
        })}
      >
        {getSummaryCards()}
        <SummaryCard
          className="mobile-trip-card"
          action={
            <FontAwesomeIcon
              className="mobile-right-chevron"
              onClick={() => onOpenModal(MyTripsModalTypes.TravelersModal)}
              icon={faChevronRight}
            />
          }
        >
          <TravelersSummary
            travelers={flight.bookedItinerary.passengers.alone
              .map(({ person }) => person?.givenName)
              .join(", ")}
          />
        </SummaryCard>
        {addOnItems.length > 0 && addOnsSummary("booking", addOnItems)}
        {flight.disruptionProtectionRebook?.DisruptionProtectionRebook !==
        DisruptionProtectionRebookEnum.IsRebook ? (
          <SummaryCard
            className="mobile-payment-summary mobile-trip-card"
            action={
              <FontAwesomeIcon
                className="mobile-right-chevron"
                onClick={() => onOpenModal(MyTripsModalTypes.PaymentModal)}
                icon={faChevronRight}
              />
            }
          >
            <PaymentSummary
              tripTotalAmount={`${getCurrencyPrefix(
                flight
              )}${getPriceWithDecimal(flight)}`}
              cardLabel={
                getLabels(flight.bookedItinerary.paymentAmountInfo).cardLabel
              }
              showCardLabel={Boolean(
                getLabels(flight.bookedItinerary.paymentAmountInfo).cardLabel
              )}
              showRewardsLabel={Boolean(
                getLabels(flight.bookedItinerary.paymentAmountInfo).rewardLabel
              )}
              cardAmount={
                getValues(flight.bookedItinerary.paymentAmountInfo).cardValue
              }
              rewardsLabel={
                getLabels(flight.bookedItinerary.paymentAmountInfo).rewardLabel
              }
              rewardsAmount={
                getValues(flight.bookedItinerary.paymentAmountInfo).rewardValue
              }
            />
          </SummaryCard>
        ) : null}
        <SummaryCard
          className="mobile-trip-card"
          action={
            <Box
              className="confirmation-mobile-action"
              onClick={() => onOpenModal(MyTripsModalTypes.ConfirmationModal)}
            >
              <Typography className="view-all-link">
                {`View all (${
                  getConfirmationNumbers({ flight, airlineMap }).length
                })`}
              </Typography>
              <FontAwesomeIcon
                className="mobile-right-chevron"
                icon={faChevronRight}
              />
            </Box>
          }
        >
          <ConfirmationSummary confirmationCode={confirmationCode || ""} />
        </SummaryCard>
        {postBookingAddOnItems.length > 0 && postBooking && (
          <>
            <Typography className="post-booking-add-on-title">
              {textConstants.POST_BOOKING_ADD_ONS}
            </Typography>
            {addOnsSummary("post-booking", postBookingAddOnItems)}
            {flight.disruptionProtectionRebook?.DisruptionProtectionRebook !==
              DisruptionProtectionRebookEnum.IsRebook && (
              <SummaryCard className="mobile-payment-summary mobile-trip-card no-gap">
                <PaymentSummary
                  tripTotalLabel={textConstants.TOTAL_POST_BOOKING_ADD_ONS}
                  tripTotalAmount={`${getCurrencyPrefix(flight)}${
                    postBooking.formattedTotal
                  }`}
                  cardLabel={
                    paymentMethods.find(
                      (method) => method.id === postBooking.postBookingPaymentId
                    )?.numberDisplay
                  }
                  showCardLabel={Boolean(
                    paymentMethods.find(
                      (method) => method.id === postBooking.postBookingPaymentId
                    )?.numberDisplay
                  )}
                  showRewardsLabel={false}
                  cardAmount={`${getCurrencyPrefix(flight)}${
                    postBooking.formattedTotal
                  }`}
                />
              </SummaryCard>
            )}
          </>
        )}
      </Box>
      <MobileActionLinks actions={getActions()} />
      {isCanceled && hasFTC && (
        <Popover
          anchorEl={ftcPopoverRef.current}
          anchorOrigin={{
            horizontal: "left",
            vertical: "bottom",
          }}
          className="how-ftc-works-popover"
          onClose={closeFTCPopover}
          open={ftcPopoverOpen}
        >
          <Typography
            className="subtitle"
            component="span"
            dangerouslySetInnerHTML={{ __html: travelCreditCopy }}
          />
        </Popover>
      )}
      {isCanceled && !hasFTC && (
        <Popover
          anchorEl={refundsPopoverRef.current}
          anchorOrigin={{
            horizontal: "left",
            vertical: "bottom",
          }}
          className="how-refunds-work-popover"
          onClose={closeRefundsPopover}
          open={refundsPopoverOpen}
        >
          <Typography className="subtitle">{HOW_REFUNDS_WORK_TEXT}</Typography>
        </Popover>
      )}
    </Box>
  );
};
