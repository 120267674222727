export const installColorConfig = (config: ColorConfig): void =>
  Object.keys(config).forEach((key) => {
    document.body.style.setProperty(`--${key}`, (config as any)[key]);
  });

export interface B2BColorConfig extends ColorConfig {
  "blue-14-transparent": string;
  "box-shadow": string;
  "backdrop-shadow": string;
  "white-transparent": string;
  "algomerch-airline": string;
  "algomerch-fareclass": string;
  "algomerch-price": string;
  "algomerch-duration": string;
  "algomerch-stops": string;
  "price-prediction-great": string;
  "price-prediction-good": string;
  "price-prediction-fair": string;
  "price-prediction-wait": string;
  "price-prediction-gradient-red": string;
  "price-prediction-gradient-orange": string;
  "price-prediction-gradient-yellow": string;
  "price-prediction-gradient-green": string;
  "price-prediction-gradient-green-transparent": string;
  "tag-background-grey": string;
  "red-error-color": string;
  "red-error-color-transparent": string;
  "warning-error-color": string;
  "warning-error-color-transparent": string;
  "warning-error-color-light": string;
  "light-spinner-blue": string;
  "ftux-background-blue": string;
  "ftux-background-blue-transparent": string;
  "takeover-background-blue": string;
  "white-banner-text": string;
  "loading-modal-background": string;
  "fare-card-background": string;
  "luxury-branding-blue": string;
}

export type ColorConfig = {
  "primary-blue": string;
  "secondary-blue": string;
  "tertiary-blue": string;
  "blue-4": string;
  "blue-5": string;
  "blue-5-transparent": string;
  "blue-9": string;
  "blue-10": string;
  "blue-11": string;
  "blue-12": string;
  "blue-13": string;
  "blue-14": string;
  "blue-15": string;
  "blue-16": string;
  "blue-17": string;
  "blue-18": string;
  "lifestyle-collection-bg-blue": string;
  "lifestyle-collection-bg-light-blue": string;
  "lifestyle-collection-border-light-blue": string;
  "user-icon-purple": string;
  "user-name-purple": string;
  "anchor-link-blue": string;
  white: string;
  "primary-text-grey": string;
  "secondary-text-grey": string;
  "tertiary-text-grey": string;
  "green-1": string;
  "green-3": string;
  "green-4": string;
  "green-5": string;
  "green-6": string;
  "grey-4": string;
  "grey-5": string;
  "grey-6": string;
  "grey-7": string;
  "grey-8": string;
  "grey-9": string;
  "grey-10": string;
  "grey-11": string;
  "grey-11-transparent": string;
  "grey-12": string;
  "grey-13": string;
  "grey-14": string;
  "grey-15": string;
  "grey-16": string;
  "grey-17": string;
  "grey-18": string;
  "inactive-grey": string;
  "inactive-button-icon-grey": string;
  "action-button-inactive-background": string;
  "action-button-inactive-text": string;
  "action-button-inactive-border": string;
  "action-button-active-background": string;
  "action-button-active-text": string;
  "action-link-active-text": string;
  "info-details-popup-background": string;
  "info-details-popup-border": string;
  "popup-card-header-border": string;
  "calendar-green": string;
  "calendar-yellow": string;
  "calendar-orange": string;
  "calendar-red": string;
  "slider-bar-highlighted": string;
  "slider-bar-inactive": string;
  "badge-green-background": string;
  "badge-blue-background": string;
  "viator-blue": string;
  "badge-red-background": string;
  "red-text": string;
  "green-7": string;
  "text-green": string;
  "text-green-2": string;
  "text-green-3": string;
  "red-1": string;
  "red-2": string;
  "red-3": string;
  "red-4": string;
  "yellow-1": string;
  "yellow-2": string;
  "yellow-3": string;
  "emphasis-blue": string;
  // CALENDAR COLORS
  "bucket-0-color": string;
  "bucket-1-color": string;
  "bucket-2-color": string;
  "bucket-3-color": string;
  "white-font-color": string;
  "grey-font-color": string;
  "date-hover-color": string;
  "date-selected-color": string;
  "date-range-limit-color": string;
  "date-disabled-color": string;
  "price-freeze-text": string;
  "toggle-grey": string;
  "white-transparent-border": string;
};

export const defaultColors: ColorConfig = {
  "primary-blue": "#009BD5",
  "secondary-blue": "#01AAE4",
  "tertiary-blue": "#01AAE4",
  "blue-4": "rgba(0, 179, 241, 0.1)",
  "blue-5": "rgb(36, 96, 130)",
  "blue-5-transparent": "rgba(36, 96, 130, 0.1)",
  "blue-9": "rgb(49, 190, 199)",
  "blue-10": "rgba(228, 246, 253, 0.25)",
  "blue-11": "#122741",
  "blue-12": "#00132B",
  "blue-13": "#F0FAFF",
  "blue-14": "#10253F",
  "blue-15": "#9FDBEF",
  "blue-16": "#0276B1",
  "blue-17": "rgba(1, 78, 116, 1)",
  "blue-18": "rgba(42, 108, 133, 1)",
  "lifestyle-collection-bg-blue": "rgba(42, 61, 84, 1)",
  "lifestyle-collection-bg-light-blue": "rgba(237, 246, 249, 1)",
  "lifestyle-collection-border-light-blue": "rgba(207, 225, 232, 1)",
  "user-icon-purple": "rgb(98, 54, 255)",
  "user-name-purple": "rgba(98, 54, 255, 0.2)",
  "anchor-link-blue": "#00b4ff",
  white: "#ffffff",
  "primary-text-grey": "#505050",
  "secondary-text-grey": "#878787",
  "tertiary-text-grey": "#929292",
  "grey-4": "#979797",
  "grey-5": "#d1d5db",
  "grey-6": "#aaaaaa",
  "grey-7": "#5a5a5a",
  "grey-8": "#f1f1f1",
  "grey-9": "rgb(80,80,80)",
  "grey-10": "#f6f6f6",
  "grey-11": "rgb(224, 224, 224)",
  "grey-11-transparent": "rgba(224, 224, 224, 0.1)",
  "grey-12": "rgb(80,80,80)",
  "grey-13": "#f8f7fa",
  "grey-14": "#636363",
  "grey-15": "#878787",
  "grey-16": "rgba(245, 245, 245, 1)",
  "grey-17": "rgba(192, 192, 192, 1)",
  "grey-18": "rgba(147, 150, 143, 1)",
  "green-3": "rgb(99, 182, 127)",
  "green-4": "rgb(224, 240, 229)",
  "emphasis-blue": "rgb(0, 195, 203)",
  "inactive-grey": "#f6f6f6",
  "inactive-button-icon-grey": "#d9d9d9",
  "action-button-inactive-background": "#f4f4f4",
  "action-button-inactive-text": "#878787",
  "action-button-inactive-border": "#d4d4d4",
  "action-button-active-background": "#246082",
  "action-button-active-text": "#ffffff",
  "action-link-active-text": "#246082",
  "popup-card-header-border": "rgba(0, 0, 0, 0.12)",
  "badge-green-background": "rgb(240, 245, 243)",
  "badge-blue-background": "rgba(23, 107, 109, 0.05)",
  "viator-blue": "rgba(23, 107, 109, 1)",
  "badge-red-background": "rgba(156, 33, 36, 0.07)",
  "red-text": "rgba(156, 33, 36)",
  "green-7": "rgba(14, 96, 24, 1)",
  "info-details-popup-background": "rgb(251, 251, 251)",
  "info-details-popup-border": "rgb(216, 216, 216)",
  "calendar-green": "#6fc28b",
  "calendar-yellow": "#f0a02a",
  "calendar-orange": "#e36624",
  "calendar-red": "#c20a21",
  "slider-bar-highlighted": "#008140",
  "slider-bar-inactive": "#e0e0e0",
  "text-green": "#63b67f",
  "text-green-2": "rgb(0, 129, 64)",
  "text-green-3": "#006E37",
  "red-1": "#fa6866",
  "red-2": "#e02020",
  "red-3": "#cf212b",
  "red-4": "rgba(171, 51, 43, 1)",
  "yellow-1": "#d99422",
  "yellow-2": "#f7b500",
  "yellow-3": "#e69100",
  "green-1": "#38b97a",
  "green-5": "#008140",
  "green-6": "rgba(14, 96, 24, 1)",
  // CALENDAR COLORS
  "bucket-0-color": "#008140",
  "bucket-1-color": "#f0a02a",
  "bucket-2-color": "#e36624",
  "bucket-3-color": "#cc2427",
  "white-font-color": "#ffffff",
  "grey-font-color": "#505050",
  "date-hover-color": "#0076c1",
  "date-selected-color": "#0076c1",
  "date-range-limit-color": "#0076c1",
  "date-disabled-color": "#d8d8d8",
  "price-freeze-text": "#31BEC7",
  "toggle-grey": "#EBEBEB",
  "white-transparent-border": "rgba(255, 255, 255, 0.25)",
};

export const defaultB2BColors: B2BColorConfig = {
  ...defaultColors,
  "primary-blue": "rgba(2, 118, 177, 1.0)",
  "secondary-blue": "rgba(2, 118, 177, 1.0)",
  "tertiary-blue": "rgba(2, 101, 151, 1.0)",
  "blue-9": "rgba(1, 61, 88, 1.0)",
  "blue-10": "rgba(0, 192, 229, .1)",
  "blue-14-transparent": "rgba(16, 37, 63, 0.9)",
  white: "rgba(255, 255, 255, 1.0)",
  "white-banner-text": "rgba(255, 255, 255, 0.75)",
  "white-transparent": "rgba(255, 255, 255, 0.48)",
  "box-shadow": "rgba(0, 0, 0, 0.05)",
  "backdrop-shadow": "rgba(0, 0, 0, 0.25)",
  "primary-text-grey": "rgba(20, 20, 20, 1.0)",
  "secondary-text-grey": "rgba(103, 104, 104, 1.0)",
  "tertiary-text-grey": "rgba(206, 207, 205, 1.0)",
  "grey-5": "rgba(206, 207, 205, 1.0)",
  "grey-6": "rgba(103, 104, 104, 1.0)",
  "grey-7": "rgba(103, 104, 104, 1.0)",
  "grey-8": "rgba(206, 207, 205, 1.0)",
  "grey-9": "rgba(103, 104, 104, 1.0)",
  "grey-10": "rgba(206, 207, 205, 1.0)",
  "grey-11": "rgba(206, 207, 205, 1.0)",
  "grey-11-transparent": "#FBFBFB",
  "grey-12": "rgba(103, 104, 104, 1.0)",
  "grey-13": "rgba(103, 104, 104, 1.0)",
  "grey-14": "rgba(103, 104, 104, 1.0)",
  "inactive-grey": "rgba(246, 246, 246, 1.0)",
  "inactive-button-icon-grey": "rgba(206, 207, 205, 1.0)",
  "action-button-inactive-background": "rgba(244, 244, 244, 1.0)",
  "action-button-inactive-text": "rgba(103, 104, 104, 1.0)",
  "action-button-inactive-border": "rgba(206, 207, 205, 1.0)",
  "action-button-active-background": "rgba(36, 96, 130, 1.0)",
  "action-button-active-text": "rgba(255, 255, 255, 1.0)",
  "action-link-active-text": "rgba(36, 96, 130, 1.0)",
  "info-details-popup-background": "rgba(251, 251, 251, 1.0)",
  "info-details-popup-border": "rgba(216, 216, 216, 1.0)",
  "calendar-green": "rgba(111, 194, 139, 1.0)",
  "calendar-yellow": "rgba(240, 160, 42, 1.0)",
  "calendar-orange": "rgba(227, 102, 36, 1.0)",
  "calendar-red": "rgba(194, 10, 33, 1.0)",
  "slider-bar-highlighted": "rgba(0, 129, 64, 1.0)",
  "slider-bar-inactive": "rgba(244, 244, 244, 1.0)",
  "badge-green-background": "rgba(240, 245, 243, 1.0)",
  "badge-blue-background": "rgba(23, 107, 109, 0.05)",
  "viator-blue": "rgba(23, 107, 109, 1)",
  "badge-red-background": "rgba(156, 33, 36, 0.07)",
  "red-text": "rgba(156, 33, 36)",
  "green-7": "rgba(14, 96, 24, 1)",
  "text-green": "rgba(99, 182, 127, 1.0)",
  "text-green-2": "rgba(0, 129, 64, 1.0)",
  "red-1": "rgba(250, 104, 102, 1.0)",
  "red-2": "rgba(224, 32, 32, 1.0)",
  "red-3": "",
  "red-4": "rgba(171, 51, 43, 1)",
  "yellow-1": "rgba(217, 148, 34, 1.0)",
  "yellow-2": "rgba(247, 181, 0, 1.0)",
  "yellow-3": "rgba(230, 145, 0, 1.0)",
  "green-1": "rgba(56, 185, 122, 1.0)",
  "light-spinner-blue": "rgba(58, 188, 253, 1.0)",
  "ftux-background-blue": "rgba(18, 39, 65, 1)",
  "ftux-background-blue-transparent": "rgba(18, 39, 65, 0.95)",
  "takeover-background-blue": "rgba(225, 245, 255, 1)",
  "price-freeze-text": "",
  // CALENDAR COLORS
  "bucket-0-color": "rgba(0, 129, 64, 1.0)",
  "bucket-1-color": "rgba(214, 129, 0, 1)",
  "bucket-2-color": "rgba(227, 102, 36, 1.0)",
  "bucket-3-color": "rgba(204, 36, 39, 1.0)",
  "white-font-color": "rgba(255, 255, 255, 1.0)",
  "grey-font-color": "rgba(20, 20, 20, 1.0)",
  "date-hover-color": "rgba(0, 118, 193, 1.0)",
  "date-selected-color": "rgba(2, 101, 151, .1)",
  "date-range-limit-color": "rgba(0, 118, 193, 1.0)",
  "date-disabled-color": "rgba(244, 244, 244, 1.0)",
  // ALGOMERCH COLORS
  "algomerch-airline": "rgba(204, 36, 39, 1.0)",
  "algomerch-fareclass": "rgba(0, 118, 193, 1.0)",
  "algomerch-price": "rgba(0, 129, 64, 1.0)",
  "algomerch-duration": "rgba(240, 160, 42, 1.0)",
  "algomerch-stops": "rgba(1, 61, 88, 1.0)",
  // PRICE PREDICTION COLORS
  "price-prediction-wait": "rgba(199, 1, 22, 1.0)",
  "price-prediction-fair": "rgba(89, 158, 42, 1)",
  "price-prediction-good": "rgba(43, 143, 54, 1)",
  "price-prediction-great": "rgba(13, 134, 61, 1)",
  // PRICE PREDICTION GRADIENT COLORS
  "price-prediction-gradient-red": "rgba(199, 1, 22, 1.0)",
  "price-prediction-gradient-orange": "rgba(236, 130, 65, 1.0)",
  "price-prediction-gradient-yellow": "rgba(249, 209, 0, 1.0)",
  "price-prediction-gradient-green": "rgba(0, 129, 64, 1.0)",
  "price-prediction-gradient-green-transparent": "rgba(0, 129, 64, 0.05)",
  "tag-background-grey": "rgba(216, 216, 216, 0.2)",
  // SCHEDULE CHANGE COLORS
  "red-error-color": "rgba(204, 36, 39, 1.0)",
  "red-error-color-transparent": "rgba(252, 213, 209, 1.0)",
  "warning-error-color": "rgba(249, 198, 6, 1.0)",
  "warning-error-color-transparent": "rgba(255, 241, 208, 1.0)",
  "warning-error-color-light": "rgba(249, 198, 6, 0.12)",
  "loading-modal-background": "rgba(1, 61, 88, 1.0)",
  "fare-card-background": "rgba(251, 251, 251, 1.0)",
  "luxury-branding-blue": "#255F82",
};
